import React, { useEffect, useState } from 'react'
import '../../../../styles.scss'
import { Image } from '../../../design-system'

interface Props {
    src: string
    alt: string
    width: number
    height: number
    aspectRatio?: number
}

const Art: React.FC<Props> = ({ alt, src, width, height }) => {
    const [maxHeight, setMaxHeight] = useState<number>(window.innerHeight)
    const [maxWidth, setMaxWidth] = useState<number>(window.innerWidth)

    const handleResize = () => {
        if (window.innerWidth < 800) {
            setMaxWidth(window.innerWidth * 0.9)
            setMaxHeight(window.innerHeight * 0.7)
        } else {
            setMaxWidth(window.innerWidth * 0.7)
            setMaxHeight(window.innerHeight * 0.86)
        }
    }

    useEffect(() => {
        handleResize()
    }, [])

    useEffect(() => {
        window.addEventListener('resize', () => {
            handleResize()
        })
    }, [maxHeight, maxWidth])

    return (
        <div className='center-items'>
            <div
                style={{
                    maxHeight: `${maxHeight}px`,
                    maxWidth: `${maxWidth}px`,
                    width: `${width}px`,
                    height: `${height}px`,
                }}
            >
                <Image src={src} alt={alt} />
            </div>
        </div>
    )
}

export { Art }
