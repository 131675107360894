import { createSlice } from '@reduxjs/toolkit'

interface WalletRequest {
    isPending: boolean
}

const initialState: WalletRequest = {
    isPending: false,
}

const walletRequestSlice = createSlice({
    name: 'walletRequest',
    initialState,
    reducers: {
        setWalletRequestPending: (state, action) => {
            state.isPending = action.payload
        },
    },
})

export const { setWalletRequestPending } = walletRequestSlice.actions
export default walletRequestSlice.reducer
