import { createSlice } from '@reduxjs/toolkit'

interface Account {
    account: string | null
}

const initialState: Account = {
    account: null,
}

const accountSlice = createSlice({
    name: 'accounts',
    initialState,
    reducers: {
        setAccount: (state, action) => {
            state.account = action.payload
        },
    },
})

export const { setAccount } = accountSlice.actions
export default accountSlice.reducer
