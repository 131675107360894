import { BigNumber } from 'ethers'
import React, { useCallback, useEffect, useState } from 'react'
import '../../../../styles.scss'
import { Column, Container, Grid, Text } from '../../../design-system'

interface Props {
    unixEndTimeInSeconds: BigNumber
}

const Timing: React.FC<Props> = ({ unixEndTimeInSeconds }) => {
    const unixEndTimeInSecondsFormatted = unixEndTimeInSeconds.toNumber() * 1000
    const endDate = new Date(unixEndTimeInSecondsFormatted)
    const endDateString = `${endDate.toLocaleDateString()} ${endDate.toLocaleTimeString()}`
    const [countdown, setCountdown] = useState<string | null>(null)

    const getCountdown = useCallback(() => {
        const now = new Date().getTime()
        const delta = unixEndTimeInSecondsFormatted - now
        const days = Math.floor(delta / (1000 * 60 * 60 * 24))
        const hours = Math.floor((delta % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        const minutes = Math.floor((delta % (1000 * 60 * 60)) / (1000 * 60))
        const seconds = Math.floor((delta % (1000 * 60)) / 1000)
        setCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s`)
    }, [unixEndTimeInSecondsFormatted])

    useEffect(() => {
        const interval = setInterval(() => getCountdown(), 1000)
        return () => clearInterval(interval)
    }, [getCountdown])

    return (
        <div>
            <Container>
                <Grid>
                    <Column className='col-25'>
                        <Text color='lightest-grey' size='16px'>{`Closes In ${countdown}`}</Text>
                    </Column>
                    <Column className='col-75'>
                        <Text color='lightest-grey' size='16px'>
                            {endDateString}
                        </Text>
                    </Column>
                </Grid>
            </Container>
        </div>
    )
}

export { Timing }
