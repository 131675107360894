import React from 'react'
import '../../../styles.scss'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    className?: string
    placeholder?: string
    type?: string
    name: string
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    children?: React.ReactNode
}

const Input: React.FC<Props> = ({
    type,
    children,
    onChange,
    name,
    className = '',
    placeholder = '',
}) => {
    return (
        <input
            name={name}
            type={type}
            className={`input ${className}`}
            placeholder={placeholder}
            onChange={onChange}
        >
            {children}
        </input>
    )
}

export { Input }
