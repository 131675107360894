import { configureStore } from '@reduxjs/toolkit'
import accountSlice from './slices/accountSlice'
import mintTransactionSlice from './slices/mintTransactionSlice'
import walletRequestSlice from './slices/walletRequestPendingSlice'

const store = configureStore({
    reducer: {
        accounts: accountSlice,
        mintTransactions: mintTransactionSlice,
        walletRequest: walletRequestSlice,
    },
})

export default store
