import React from 'react'
import '../../../../styles.scss'
import { Text, Grid, Container, Column } from '../../../design-system'
import { BigNumber, ethers } from 'ethers'

interface Props {
    mintPriceInWei: BigNumber
    totalSupply: BigNumber
}

const Price: React.FC<Props> = ({ mintPriceInWei, totalSupply }) => {
    const mintPriceInEth = ethers.utils.formatEther(mintPriceInWei)
    return (
        <div>
            <Container>
                <Grid>
                    <Column className='col-25'>
                        <Text color='lightest-grey' size='16px'>{`Ξ ${mintPriceInEth}`}</Text>
                    </Column>
                    <Column className='col-75'>
                        <Text
                            color='lightest-grey'
                            size='16px'
                        >{`Total Minted: ${totalSupply.toString()}`}</Text>
                    </Column>
                </Grid>
            </Container>
        </div>
    )
}

export { Price }
