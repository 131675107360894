import React from 'react'
import '../../../styles.scss'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string
    isDisabled?: boolean
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    children: React.ReactNode
}

const Button: React.FC<Props> = ({
    children,
    onClick,
    className = '',
    isDisabled,
    type = 'submit',
}) => {
    return (
        <button
            type={type}
            disabled={isDisabled}
            className={`button ${className}`}
            onClick={onClick}
        >
            {children}
        </button>
    )
}

export { Button }
