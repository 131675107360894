import { BigNumber } from 'ethers'
import { useCallback, useEffect, useState } from 'react'
import { Address, useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi'
import api from '../../../api'
import { CONTRACT_ABI, CONTRACT_ADDRESS } from '../../../constants'

interface MintArgs {
    captchaToken: string
    amount: number
    mintPriceInWei: BigNumber
    buyerAddress: Address | undefined
    contractAddress: string
}

export const useMint = ({
    captchaToken,
    amount,
    mintPriceInWei,
    buyerAddress,
    contractAddress,
}: MintArgs) => {
    // State for message hash and defender signature
    const [messageHash, setMessageHash] = useState<string>('')
    const [defenderSignature, setDefenderSignature] = useState<string>('')

    // Prepare the contract write with the necessary parameters
    const { config, error, isError } = usePrepareContractWrite({
        address: CONTRACT_ADDRESS,
        abi: CONTRACT_ABI,
        functionName: 'mint',
        args: [messageHash, defenderSignature, amount],
        enabled: Boolean(messageHash && defenderSignature),
        overrides: {
            from: buyerAddress,
            value: mintPriceInWei.mul(amount),
            gasLimit: BigNumber.from(120000),
        },
    })

    // Execute the contract write
    const { data, write } = useContractWrite(config)
    // Track the transaction status
    const { isLoading, isSuccess } = useWaitForTransaction({
        hash: data?.hash,
    })

    const fetchMessageSignature = useCallback(async () => {
        if (!buyerAddress) {
            return
        }
        if (amount < Number(1) || amount > Number(5)) {
            return
        }
        try {
            const response = await api.getMintMessageSignature(
                captchaToken,
                amount,
                buyerAddress as string,
                contractAddress,
            )
            const { hash, signature } = JSON.parse(response)
            setMessageHash(hash)
            setDefenderSignature(signature)
        } catch (error) {
            console.error(error)
            return
        }
    }, [captchaToken, amount, buyerAddress, contractAddress])

    useEffect(() => {
        fetchMessageSignature()
    }, [fetchMessageSignature])

    // Execute the actual minting process once the message hash and signature are set from the prepare step
    const mintTokens =
        write && messageHash && defenderSignature
            ? async () => {
                  await write()
              }
            : undefined

    return {
        error,
        isError,
        isLoading,
        isSuccess,
        mintTokens,
    }
}
