import { BigNumber, ethers } from 'ethers'
import React, { useCallback, useEffect, useState } from 'react'
import { useAccount, useContractReads, useProvider } from 'wagmi'
import { CONTRACT_ABI, CONTRACT_ADDRESS } from '../../../../constants'
import '../../../../styles.scss'
import { Text } from '../../../design-system'
import { MintForm } from '../MintForm'
import { Price } from '../Price'
import { Timing } from '../Timing'

interface Props {
    description: string
    contractAddress: string
    saleType: string
    children?: React.ReactNode
}

const Details: React.FC<Props> = ({ description, saleType }) => {
    const provider = useProvider()
    const { isConnected } = useAccount()
    const [mintPriceInWei, setMintPriceInWei] = useState<BigNumber | null>(null)
    const [unixEndTimeInSeconds, setUnixEndTimeInSeconds] = useState<BigNumber | null>(null)
    const [totalSupply, setTotalSupply] = useState<BigNumber | null>(null)

    const { data, isError } = useContractReads({
        contracts: [
            {
                address: CONTRACT_ADDRESS,
                abi: CONTRACT_ABI,
                functionName: 'mintPrice',
            },
            {
                address: CONTRACT_ADDRESS,
                abi: CONTRACT_ABI,
                functionName: 'endTime',
            },
            {
                address: CONTRACT_ADDRESS,
                abi: CONTRACT_ABI,
                functionName: 'totalSupply',
            },
        ],
    })

    const getCollectionDetails = useCallback(async () => {
        let mintPrice: BigNumber
        let endTime: BigNumber
        let totalSupply: BigNumber

        if (data && !isError) {
            mintPrice = data[0] as BigNumber
            endTime = data[1] as BigNumber
            totalSupply = data[2] as BigNumber
        } else {
            // we shouldn't be here, but if we are, we'll use the fallback provider
            const contract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, provider)
            mintPrice = await contract.mintPrice()
            endTime = await contract.endTime()
            totalSupply = await contract.totalSupply()
        }
        setMintPriceInWei(mintPrice)
        setUnixEndTimeInSeconds(endTime)
        setTotalSupply(totalSupply)
    }, [data, isError, provider])

    useEffect(() => {
        getCollectionDetails()
    }, [isConnected, getCollectionDetails])

    return (
        <div className='small-margin'>
            <div className='small-margin mobile-desc'>
                <Text size='12px' color='lightest-grey'>
                    {description}
                </Text>
            </div>
            <div className='small-margin'>
                <Text color='lightest-grey' size='24px' weight='semi-bold'>
                    {saleType}
                </Text>
            </div>
            <div className='small-margin'>
                {mintPriceInWei && totalSupply && (
                    <Price mintPriceInWei={mintPriceInWei} totalSupply={totalSupply} />
                )}
            </div>
            <div className='small-margin'>
                {unixEndTimeInSeconds && <Timing unixEndTimeInSeconds={unixEndTimeInSeconds} />}
            </div>
            <div className='small-margin'>
                {mintPriceInWei && (
                    <MintForm mintPriceInWei={mintPriceInWei} contractAddress={CONTRACT_ADDRESS} />
                )}
            </div>
        </div>
    )
}

export { Details }
