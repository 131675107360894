import React from 'react'
import '../../styles.scss'

import { Grid, Text, Line } from '../design-system'
import { ConnectButton } from '@rainbow-me/rainbowkit'

const Header: React.FC = () => {
    return (
        <div className='header-container'>
            <Grid>
                <div className='header'>
                    <div className='box-text-left margin-right-large clickable-div'>
                        <Text color='lightest-grey' size='24px' weight='semi-bold'>
                            KHRÔMA
                        </Text>
                    </div>
                    <div className='box-text-right'>
                        <ConnectButton />
                    </div>
                </div>
            </Grid>
            <Line />
        </div>
    )
}

export { Header }
