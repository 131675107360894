import React from 'react'
import '../../../styles.scss'

interface ColumnProps {
    className?: string
    id?: string
    children: React.ReactNode
}

const Column: React.FC<ColumnProps> = ({ children, className = 'col-50', id = '' }) => {
    return (
        <div id={id} className={`col ${className}`}>
            {children}
        </div>
    )
}

export { Column }
