import React, { useRef, useState, useEffect } from 'react'
import { Art, Details } from './components'
import { Grid, Column } from '../design-system'
import { useParams } from 'react-router-dom'
import api from '../../api'

interface CollectionDetails {
    description: string
    contractAddress: string
    saleType: string
}

const Mint: React.FC = () => {
    const { collectionSlug } = useParams()
    const aspectRatio = 4375 / 3500
    const parentRef = useRef<HTMLDivElement>(null)
    const [width, setWidth] = useState<number>(window.innerWidth * 0.75 * aspectRatio)
    const [height, setHeight] = useState<number>(window.innerHeight * 0.75)
    const [collectionDetails, setCollectionDetails] = useState<CollectionDetails>({
        description: '',
        contractAddress: '',
        saleType: '',
    })

    useEffect(() => {
        const handleResize = () => {
            const column = document.getElementById('art-column')
            const colHeight = column?.clientHeight ?? 0
            const colWidth = column?.clientWidth ?? 0
            if (colWidth < colHeight) {
                setWidth(colWidth)
                setHeight(colWidth * aspectRatio)
            } else {
                setWidth(colHeight / aspectRatio)
                setHeight(colHeight)
            }
        }

        handleResize()
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [aspectRatio])

    useEffect(() => {
        const fetchCollection = async () => {
            try {
                if (!collectionSlug) throw new Error('No collection slug provided')
                const response = await api.getCollectionDetails(collectionSlug)
                setCollectionDetails(response)
            } catch (error) {
                console.error(error)
            }
        }

        fetchCollection()
    }, [collectionSlug])

    return (
        <div className='center-items small-margin'>
            <Grid>
                <Column id='art-column' className='col-75 mobile-mint-art-col'>
                    <div ref={parentRef}>
                        <Art
                            src='https://4v3nz7zp6mwpdbqcbd635famul2qawjw4qblgthrvxe2dzaz6mjq.arweave.net/5Xbc_y_zLPGGAgj9vpQMovUAWTbkArNM8a3JoeQZ8xM'
                            alt='Art'
                            width={width}
                            height={height}
                        />
                    </div>
                </Column>
                <Column className='mobile-mint-detail-col col-25'>
                    <Details
                        description={collectionDetails.description}
                        contractAddress={collectionDetails.contractAddress}
                        saleType={collectionDetails.saleType}
                    />
                </Column>
            </Grid>
        </div>
    )
}

export { Mint }
