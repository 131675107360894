import React from 'react'
import '../../../styles.scss'

interface LineProps {
    className?: string
}

const Line: React.FC<LineProps> = ({ className }) => {
    return <div className={`line ${className}`} />
}

export { Line }
