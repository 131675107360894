import { createSlice } from '@reduxjs/toolkit'

interface MintTransaction {
    transactionHash: string | null
}

const initialState: MintTransaction = {
    transactionHash: null,
}

const MintTransactionSlice = createSlice({
    name: 'mintTransactions',
    initialState,
    reducers: {
        setMintTransaction: (state, action) => {
            state.transactionHash = action.payload
        },
    },
})

export const { setMintTransaction } = MintTransactionSlice.actions
export default MintTransactionSlice.reducer
