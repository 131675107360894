import { Chain, goerli, mainnet } from 'wagmi/chains'

export const LAMBDA_API_KEY = process.env.REACT_APP_LAMBDA_API_KEY
export const LAMBDA_URL = process.env.REACT_APP_LAMBDA_URL

const getChains = (): Chain[] => {
    const chainStrings = process.env.REACT_APP_CHAINS?.split(',') ?? []
    const chains = []
    if (chainStrings.includes('goerli')) {
        chains.push(goerli)
    }
    if (chainStrings.includes('mainnet')) {
        chains.push(mainnet)
    }
    return chains
}

export { getChains }
