import HCaptcha from '@hcaptcha/react-hcaptcha'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import { BigNumber } from 'ethers'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { useAccount } from 'wagmi'
import { Button, Input, Text } from '../../../design-system'
import { useMint } from '../../hooks/useMint'

const REACT_APP_HCAPTCHA_SITE_KEY = process.env.REACT_APP_HCAPTCHA_SITE_KEY ?? ''

interface Props {
    contractAddress: string
    mintPriceInWei: BigNumber
}

const MintForm: React.FC<Props> = ({ mintPriceInWei, contractAddress }) => {
    const [captchaToken, setCaptchaToken] = useState<string>('')
    const [amount, setAmount] = useState<number>(Number(1))
    const captchaRef = useRef<HCaptcha>(null)
    const { openConnectModal } = useConnectModal()
    const { address, isConnected } = useAccount()

    const { error, isError, isLoading, isSuccess, mintTokens } = useMint({
        captchaToken,
        amount,
        mintPriceInWei,
        buyerAddress: address,
        contractAddress,
    })

    const handleAmountChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setAmount(Number(event.target.value))
    }, [])

    useEffect(() => {
        console.log(REACT_APP_HCAPTCHA_SITE_KEY)
    }, [])

    const handleCaptchaVerify = useCallback(
        async (token: string) => {
            setCaptchaToken(token)
            if (mintTokens) await mintTokens()
        },
        [mintTokens],
    )

    const handleButtonClick = useCallback(() => {
        if (!isConnected) {
            openConnectModal?.()
        } else {
            captchaRef.current?.execute()
        }
    }, [isConnected, openConnectModal])

    return (
        <>
            <div className='mint-button-wrapper'>
                <Input
                    type='number'
                    name='mintAmount'
                    className='mint-input'
                    placeholder='1'
                    onChange={handleAmountChange}
                />
            </div>
            <div className='mint-button-wrapper'>
                <HCaptcha
                    size='invisible'
                    sitekey={REACT_APP_HCAPTCHA_SITE_KEY}
                    onVerify={handleCaptchaVerify}
                    ref={captchaRef}
                />
                <Button
                    isDisabled={isLoading || isError}
                    className='mint-button'
                    onClick={handleButtonClick}
                >
                    <Text color='lightest-grey' size='16px' weight='semi-bold'>
                        Mint
                    </Text>
                </Button>
                {isError && <Text color='red'>{error?.message}</Text>}
            </div>
        </>
    )
}

export { MintForm }
