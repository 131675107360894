import React from 'react'
import '../../../styles.scss'

interface ImageProps {
    src: string
    className?: string
    alt: string
}

const Image: React.FC<ImageProps> = ({ src, alt, className }) => {
    return (
        <img
            style={{ width: '100%', height: '100%' }}
            className={`${className}`}
            src={src}
            alt={alt}
        />
    )
}

export { Image }
