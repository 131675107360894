import React from 'react'
import '../../../styles.scss'

interface Props {
    size?: string
    weight?: string
    color?: string
    className?: string
    children: React.ReactNode
}

const Text: React.FC<Props> = ({
    children,
    size = '16px',
    weight = 'normal',
    color = 'black',
    className = '',
}) => {
    return (
        <div
            className={`text text-size-${size} text-weight-${weight} text-color-${color} ${className}`}
        >
            {children}
        </div>
    )
}

export { Text }
