import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { configureChains, createClient, WagmiConfig } from 'wagmi'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { getChains } from './config'
import { Header } from './pages/components'
import { Mint } from './pages/Mint'

import { getDefaultWallets, midnightTheme, RainbowKitProvider } from '@rainbow-me/rainbowkit'
import '@rainbow-me/rainbowkit/styles.css'

const REACT_APP_ALCHEMY_API_KEY = process.env.REACT_APP_ALCHEMY_API_KEY ?? ''

// conditional chain configuration
const chains = getChains()

// Wagmi client
const { provider } = configureChains(chains, [
    alchemyProvider({ apiKey: REACT_APP_ALCHEMY_API_KEY }),
])

const { connectors } = getDefaultWallets({
    appName: 'My RainbowKit App',
    chains,
})

const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider,
})

const router = createBrowserRouter([
    {
        path: '/',
        element: <Mint />,
        children: [],
    },
    {
        // when the URL matches this segment
        element: <Mint />,
        path: 'collections/:collectionSlug',

        // with this data loaded before rendering
        loader: async ({ params }) => {
            return fetch(`/collections/${params.collectionSlug}`, {})
        },
    },
])

function App() {
    return (
        <>
            <WagmiConfig client={wagmiClient}>
                <RainbowKitProvider chains={chains} theme={midnightTheme()} modalSize='compact'>
                    <div className='app'>
                        <div className='page-wrapper'>
                            <Header />
                            <div className='main-content'>
                                <RouterProvider router={router} />
                            </div>
                        </div>
                    </div>
                    ;
                </RainbowKitProvider>
            </WagmiConfig>
        </>
    )
}

export default App
