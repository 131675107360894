import { AxiosInstance } from 'axios'
import { LAMBDA_API_KEY } from '../config'

class ApiService {
    axios: AxiosInstance

    constructor(axios: AxiosInstance) {
        this.axios = axios
    }

    getMintMessageSignature = async (
        token: string,
        mintAmount: number,
        address: string,
        contractAddress: string,
    ) => {
        return this.axios
            .post(
                '/verify-mint',
                {
                    token: token,
                    mintAmount: mintAmount,
                    address: address,
                    contractAddress: contractAddress,
                },
                {
                    headers: { 'x-api-key': LAMBDA_API_KEY },
                },
            )
            .then((response) => {
                return response.data as string
            })
            .catch((error) => {
                return Promise.reject(error.response as string)
            })
    }

    getCollectionDetails = async (slug: string) => {
        return this.axios
            .get(`/collection-details?id=${slug}`)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                return Promise.reject(error.response.data as string)
            })
    }
}

export default ApiService
